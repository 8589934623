<template>
  <SourceCreate />
  <div class="nav-content blackBgColor">
    <ul class="topMenu">
      <div class="FeatureList">
        <li :class="{ activatedlineNav: $route.path === '/home' }">
          <a href="/home">
            <img :src="hovering
                ? require('../assets/icons/yellow_home.png')
                : require('../assets/icons/black_home.png')
              " @mouseover="hovering = true" @mouseleave="hovering = false" alt="Home" />
            <span @mouseover="hovering = true" @mouseleave="hovering = false">Home</span>
          </a>
        </li>
        <li :class="{ activeNav: $route.path === '/myTask' }">
          <a href="/myTask"><img :src="hoveringTask
              ? require('../assets/icons/yellow_Task.png')
              : require('../assets/icons/black_Task.png')
            " @mouseover="hoveringTask = true" @mouseleave="hoveringTask = false" alt="Task" />
            <span @mouseover="hoveringTask = true" @mouseleave="hoveringTask = false">Task</span>
          </a>
        </li>
        <li :class="{ activatedlineNav: $route.path === '/Inbox' }">
          <a href="/UserInbox"><img :src="hoveringInbox
              ? require('../assets/icons/yellow_Inbox.png')
              : require('../assets/icons/black_Inbox.png')
            " @mouseover="hoveringInbox = true" @mouseleave="hoveringInbox = false" alt="Inbox" />
            <span @mouseover="hoveringInbox = true" @mouseleave="hoveringInbox = false">Mailbox</span>
          </a>
        </li>
        <li :class="{ activatedlineNav: $route.path === '/Calender' }">
          <a href="/Calendar"><img :src="hoveringCalender
              ? require('../assets/icons/yellow_Calendar.png')
              : require('../assets/icons/black_Calendar.png')
            " @mouseover="hoveringCalender = true" @mouseleave="hoveringCalender = false" alt="Calendar" />
            <span @mouseover="hoveringCalender = true" @mouseleave="hoveringCalender = false">Calendar</span>
          </a>
        </li>

        <li :class="{ activatedlineNav: $route.path.startsWith('/Sales') }" v-if="showModule('Sales')">
          <a @click="toggleSalesDropdown">
            <img :src="hoveringSales
                ? require('../assets/icons/yellow_Sales.png')
                : require('../assets/icons/black_Sales.png')
              " @mouseover="hoveringSales = true" @mouseleave="hoveringSales = false" alt="Sales" />
            <span @mouseover="hoveringSales = true" @mouseleave="hoveringSales = false">Sales</span>
          </a>
          <div class="SalesDropDown mt-1" v-show="showSalesDropdown">
            <div class="inline-content">
              <a :class="{ activatedline: $route.path === '/Sales/Dashboard' }"
                @click="goToGlobalDashboard('SalesDashBoard')">Dashboard</a>
            </div>
            <div class="inline-content">
              <a
              href="/SalesTarget"
                :class="{ activatedline: $route.path === '/Sales/SalesTarget' }"
               
                >Sales Target</a
              >
            </div>
            <div class="inline-content">
              <a
                href="/SalesSource"
                :class="{ activatedline: $route.path === '/Sales/Property' }"
                >Source</a
              >
              <a class="plus-icon">
                <img v-if="isSalesManager()" src="../assets/icons/plusIconSales.png" alt="Add" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" data-bs-whatever="@mdo" style="cursor: pointer" />
              </a>
            </div>

            <div class="inline-content">
              <a href="/MyLead" @click="toggleLeadDropdown" class="LeadTag"
                :class="{ activatedline: $route.path === '/Sales/Leads' }">My Leads
              </a>
            </div>
            <div class="inline-content">
              <a href="/EstimateList" :class="{ activatedline: $route.path === '/Sales/Estimates' }">Estimates</a>
              <a href="/CreateEstimate" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="Add" />
              </a>
            </div>
            <div class="inline-content">
              <a href="/SalesOrderList" :class="{ activatedline: $route.path === '/Sales/SalesOrder' }">Sales Order</a>
            </div>
            <div class="inline-content">
              <a href="/Customer" :class="{ activatedline: $route.path === '/Sales/Customers' }">Customers</a>
            </div>
            <div class="inline-content">
              <a @click="goToCompanyDocuments('sales')"
                :class="{ activatedline: $route.path === '/Sales/Setting' }">Documents</a>
            </div>
          </div>
        </li>

        <li :class="{ activatedlineNav: showDesignDropDown }" v-if="showModule('Designing')">
          <a @click="toggleDesignsDropdown">
            <img :src="hoveringDesign
                ? require('../assets/icons/yellow_Designing.png')
                : require('../assets/icons/black_Designing.png')
              " @mouseover="hoveringDesign = true" @mouseleave="hoveringDesign = false" alt="Design" />
            <span @mouseover="hoveringDesign = true" @mouseleave="hoveringDesign = false">
              Design
            </span>
          </a>
          <div class="DesignDropDown" v-show="showDesignDropDown">
            <div class="inline-content">
              <a :class="{ activatedline: $route.path === '/Design/Dashboard' }"
                @click="goToGlobalDashboard('DesignDashBoard')">Dashboard</a>
            </div>
            <div class="inline-content">
              <a href="/DesignProjectListBoard" style="color: #ffffff">Projects</a>
            </div>
            <div class="inline-content">
              <a @click="goToCompanyDocuments('design')"
                :class="{ activatedline: $route.path === '/CompanyDocuments/design' }">Documents</a>
            </div>
          </div>
        </li>
        <li :class="{ activatedlineNav: showProcurementDrowdown }" v-if="showModule('Procurement')">
          <a @click="toggleProcurementDropdown">
            <img :src="hoveringProcureMent
                ? require('../assets/icons/yellow_Procurement.png')
                : require('../assets/icons/black_Procurement.png')
              " @mouseover="hoveringProcureMent = true" @mouseleave="hoveringProcureMent = false"
              alt="Procurement icon" />
            <span @mouseover="hoveringProcureMent = true" @mouseleave="hoveringProcureMent = false">
              Procurement
            </span>
          </a>
          <div class="ProcurementDropDown" v-show="showProcurementDrowdown">
            <div class="inline-content">
              <a @click="goToGlobalDashboard('ProcurementDashboard')" :class="{
                activatedline: $route.path === '/GlobalDashboard/ProcurementDashboard',
              }">Dashboard</a>
            </div>
            <div class="inline-content">
              <a href="/ProductMaster">Product Master</a>
            </div>
            <div class="inline-content">
              <a @click="goToProcurementTableByProjectStatus('In Progress')">Projects</a>
            </div>
            <div class="inline-content">
              <a href="/ProcurementVendorList">Vendors</a>
              <a href="/ProcurementVendorDetails" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" />
              </a>
            </div>
            <div class="inline-content">
              <a @click="purchaseList('All')">Purchase Request</a>
              <a @click="goToPurchaseCreate('Procurement')" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" />
              </a>
            </div>
            <div class="inline-content">
              <a href="/PurchaseOrderList">Purchase Order</a>
              <a href="/CreatePurchaseOrder" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" />
              </a>
            </div>
            <div class="inline-content">
              <a href="/PurchaseInvoiceList">Purchase Invoice</a>
              <a class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" @click="openModalPI(null)" data-bs-toggle="modal"
                  data-bs-target="#CreatePurchaseInvoice" />
              </a>
            </div>

            <div class="inline-content">
              <a href="/ProcurementDebitNotes">Debit Note</a>
              <a href="/CreateDebitNote" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" />
              </a>
            </div>
            <div class="inline-content">
              <a href="/ProcurementTransitList">Transit</a>
              <a href="/AddTicket" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" />
              </a>
            </div>
            <div class="inline-content">
              <a @click="goToCompanyDocuments('procurement')" :class="{
                activatedline: $route.path === '/CompanyDocuments/procurement',
              }">Documents</a>
            </div>
          </div>
        </li>
        <li :class="{ activatedlineNav: showOnsiteDropdown }" v-if="showModule('Onsite')">
          <a @click="toggleOnsiteDropdown">
            <img :src="hoveringOnsite
                ? require('../assets/icons/yellow_OnSite.png')
                : require('../assets/icons/black_OnSite.png')
              " @mouseover="hoveringOnsite = true" @mouseleave="hoveringOnsite = false" alt="" />
            <span @mouseover="hoveringOnsite = true" @mouseleave="hoveringOnsite = false">
              OnSite</span>
          </a>
          <div class="OnsiteDropDown" v-show="showOnsiteDropdown">
            <span class="inline-content">
              <a @click="goToGlobalDashboard('OnsiteDashboard')" :class="{
                activatedline: $route.path === '/GlobalDashboard/OnsiteDashboard',
              }">Dashboard</a>
            </span>

            <span class="inline-content">
              <a @click="purchaseList('Onsite')">Purchase Request</a>
              <a @click="goToPurchaseCreate('Onsite')" class="plus-icon">
                <img src="../assets/icons/plusIconSales.png" alt="" />
              </a>
            </span>

            <span class="inline-content">
              <a href="/PettyCashOnsite">Petty Cash</a>
            </span>

            <span class="inline-content">
              <a href=" /ContractorsList">Contractor</a>
            </span>
            <span class="inline-content">
              <a @click="goToCompanyDocuments('onSite')"
                :class="{ activatedline: $route.path === '/CompanyDocuments/onSite' }">Documents</a>
            </span>
          </div>
        </li>
        <!-- <li :class="{ activeNav: $route.path === '/Access Key' }">
          <a href="/AccessKey"><img :src="hoveringTask
              ? require('../assets/icons/yellow_Task.png')
              : require('../assets/icons/black_Task.png')
            " @mouseover="hoveringTask = true" @mouseleave="hoveringTask = false" alt="Access Keys" />
            <span @mouseover="hoveringTask = true" @mouseleave="hoveringTask = false">Access Keys</span>
          </a>
        </li> -->
        
      </div>
      
      <!-- <li :class="{ activatedlineNav: $route.path === '/Customer Support' }" class="customerSupport">
        <a href="/Customer Support"><img :src="hoveringHelp
            ? require('../assets/icons/yellow_Help.png')
            : require('../assets/icons/black_Help.png')
          " @mouseover="hoveringHelp = true" @mouseleave="hoveringHelp = false" alt="" />
          <span @mouseover="hoveringHelp = true" @mouseleave="hoveringHelp = false" class="ms-1">
            Help
          </span>
        </a>
      </li> -->
    </ul>
  </div>
  <CreatePurchaseInvoice :SelectedPIId="selectedPIId" />
</template>

<script>
import CreatePurchaseInvoice from "./Modal/CreatePurchaseInvoice.vue";
import SourceCreate from "./Modal/SourceCreate.vue";

export default {
  name: "Nav",
  components: {
    SourceCreate,
    CreatePurchaseInvoice,
  },
  data() {
    return {
      selectedPIId: null,
      hoveringHelp: false,
      hoveringProcureMent: false,
      hoveringOnsite: false,
      hoveringDesign: false,
      hoveringSales: false,
      hoveringMarketing: false,
      hoveringCalender: false,
      hoveringInbox: false,
      hovering: false,
      hoveringTask: false,
      initials: "",
      showSalesDropdown: false,
      showProjectModal: false,
      showDesignProjectModal: false,

      employees: [],
      showPropertyDropdown: false,
      properties: [],
      showLeadDropdown: false,
      showDesignProjectDropdown: false,
      showDesignDropDown: false,
      showProcurementDrowdown: false,
      showOnsiteDropdown: false,
      DesignProjects: [],
      DesignProjectList: [],
      selectedDesignProject: "",
      showDropdownOfDesignProjectList: false,
      DesignProjectId: "",
      selectedSalesPerson: "",
      showDropdown1: false,
      navContentWidth: 0,
      showDropdown: false,
    };
  },

  methods: {
    openModalPI(PIId) {
      console.log(PIId);
      this.selectedPIId = PIId;
      console.log(PIId); // Set the selected lead ID when a lead is clicked
    },

    showModule(teamName) {
      return this.$LocalUser?.employeeRoles?.some((role) => role.team === teamName);
    },

    isSalesManager() {
      return this.$LocalUser?.employeeRoles?.some(
        (role) => role.team === "Sales" && role.role === "Manager"
      );
    },

    updateDropdownVisibility() {
      this.showDropdown = this.navContentWidth >= 100;
    },
    searchSalesPerson() {
      this.showDropdown1 = this.selectedSalesPerson.trim() !== "";
    },
    selectSalesPerson(salesPerson) {
      this.selectedSalesPerson = salesPerson["users.firstname"];
      this.DesignProjectData.assignTo = salesPerson.id;
      this.showDropdown1 = false;
    },

    selectDesignProject(project) {
      this.selectedDesignProject = project.projectName;
      this.DesignProjectData.customerNumber = project["customers.name"];
      this.DesignProjectId = project.id;
      this.showDropdownOfDesignProjectList = false;
    },


    toggleProcurementDropdown() {
      this.showProcurementDrowdown = !this.showProcurementDrowdown;
    },
    toggleOnsiteDropdown() {
      this.showOnsiteDropdown = !this.showOnsiteDropdown;
    },
    toggleDesignProjects() {
      this.showDesignProjectDropdown = !this.showDesignProjectDropdown;
    },
    toggleshowDesignProjectModal() {
      this.showDesignDropDown = true;
      this.showDesignProjectModal = !this.showDesignProjectModal;
    },
    toggleDesignsDropdown() {
      this.showDesignDropDown = !this.showDesignDropDown;
    },
    hideDesignProjectModal() {
      this.showDesignProjectModal = false;
    },
    toggleSalesDropdown() {
      this.showSalesDropdown = !this.showSalesDropdown;
    },
    toggleshowProjectModal() {
      this.showProjectModal = !this.showProjectModal;
    },
    hideProjectModal() {
      this.showProjectModal = false;
    },

    resetForm() {
      this.propertyData = {
        name: "",
        address: "",
        city: "",
        state: "",
      };
    },

    togglePropertyDropdown() {
      this.showSalesDropdown = true;
      this.showPropertyDropdown = !this.showPropertyDropdown;
    },

    goToGlobalDashboard(dashboardType) {
      this.$router.push({
        name: "GlobalDashboard",
        params: { DashboardType: dashboardType },
      });
    },
    purchaseList(moduleName) {
      this.$router.push({
        name: "PurchaseRequestList",
        params: { moduleName: moduleName },
      });
    },
    goToPurchaseCreate(moduleName) {
      this.$router.push({
        name: "PurchaseRequestDetails",
        params: { moduleName: moduleName },
      });
    },
    goToCompanyDocuments(moduleName) {
      this.$router.push({
        name: "CompanyDocuments",
        params: { moduleName: moduleName },
      });
    },

    goToOnsiteDashboard(dashboardType) {
      this.$router.push({
        name: "GlobalDashboard",
        params: { DashboardType: dashboardType },
      });
    },
    goToDesignDashboard(dashboardType) {
      this.$router.push({
        name: "GlobalDashboard",
        params: { DashboardType: dashboardType },
      });
    },
    goToProcurementTableByProjectStatus(statusName) {
      this.$router.push({
        name: "ProcurementProjectTableByStatus",
        params: { statusName: statusName ,
          selectedEmployeeId: this.$LocalUser.user.employeeId
        },
      });
    },
    goToProjectList(property) {
      this.showPropertyDropdown = true;
      this.$router.push({
        name: "PropertyList",
        params: { projectName: property.name, Id: property.id },
      });
    },
    goToDesignProjectList(project) {
      this.$router.push({
        name: "DesignProjectProfile",
        params: { projectName: project.projectName, Id: project.id },
      });
    },
    toggleLeadDropdown() {
      this.showSalesDropdown = true;
      this.showLeadDropdown = !this.showLeadDropdown;
    },
  },
  mounted() { },
};
</script>

<style>
.SalesDropDown,
.ProcurementDropDown,
.DesignDropDown,
.OnsiteDropDown {
  display: flex;
  flex-direction: column;
  z-index: 99;
  background-color: #000000;
  border-radius: 10px;
  margin-left: 48px;
  padding: 2% 5%;
  position: relative;
}

.PropertyDropDown,
.ProjectDropDown,
.leadDropdown,
.EstimateDropdown {
  position: relative;
  top: 100%;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: auto;

  background-color: transparent;
}

.project-list,
.property-list,
.leadList,
.EstimateList {
  display: flex;
  flex-direction: column;
  position: relative;
}

.property-list a,
.project-list a,
.leadList a,
.EstimateList a {
  padding: 4px 8px;
  text-decoration: none;
  color: white;
  font-size: 1rem;
}

.property-list a:hover,
.project-list a:hover,
.leadList a:hover,
.EstimateList a:hover {
  background-color: #ffbd59;
  border-radius: 20px;
  color: #084f5b;
}

.OnsiteDropDown span,
.SalesDropDown span,
.ProcurementDropDown span,
.DesignDropDown span {
  align-items: center;
  display: inline;
  flex-direction: row;
  flex-direction: column;
  text-align: left;
  padding: 3% 0;
  position: relative;
}

.OnsiteDropDown .inline-content,
.SalesDropDown .inline-content,
.DesignDropDown .inline-content,
.ProcurementDropDown .inline-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
}

.OnsiteDropDown .inline-content a,
.SalesDropDown .inline-content a,
.DesignDropDown .inline-content a,
.ProcurementDropDown .inline-content a {
  display: inline;
  vertical-align: middle;
  align-items: center;
  font-size: 12px;
  position: relative;
  color: #ffffff;
}

.OnsiteDropDown .inline-content img,
.ProcurementDropDown .inline-content img,
.SalesDropDown .inline-content img,
.DesignDropDown .inline-content img {
  width: 14px;
  height: 14px;
  margin-left: 10%;
}

.SalesDropDown .inline-content,
.DesignDropDown .inline-content,
.OnsiteDropDown .inline-content,
.ProcurementDropDown .inline-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3% 0;
  position: relative;
}

.SalesDropDown .inline-content a:nth-child(1),
.DesignDropDown .inline-content a:nth-child(1),
.OnsiteDropDown .inline-content a:nth-child(1),
.ProcurementDropDown .inline-content a:nth-child(1) {
  text-align: left;
}

.SalesDropDown .inline-content a:nth-child(2),
.DesignDropDown .inline-content a:nth-child(2),
.OnsiteDropDown .inline-content a:nth-child(2),
.ProcurementDropDown .inline-content a:nth-child(2) {
  text-align: right;
}

#markersDropOfdown {
  position: relative;
  align-items: center;
  cursor: pointer;
}

.SalesProjectModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.SalesProjectModal .modal-content {
  width: 60%;
  max-width: 800px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow: hidden;
}

.SalesProjectRegister {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  top: 30%;
  left: 40%;
  position: fixed;
  width: 30%;
  height: 40%;
  border: 2px solid black;
  background-color: #396e76;
  letter-spacing: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 5);

  z-index: 9999;
}

.SalesProjectRegister header img {
  width: 3%;
  height: 2%;
  top: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}

.SalesProjectRegister header {
  display: flex;
  align-items: center;
  padding: 2%;
}

.SalesProjectRegister header h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 35%;
}

.salesProjectForm {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

.formField {
  margin: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.formField label {
  white-space: nowrap;
  left: 0;
  margin-left: 10%;
  position: absolute;
}

.formField input {
  right: 0;
  margin-right: 10%;
  position: absolute;
  outline: none;
  border: none;
  padding: 1% 6%;
  background-color: #084f5b;
  white-space: nowrap;
}

.SalesProjectRegister footer {
  margin-top: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SalesProjectRegister footer button {
  letter-spacing: 2px;
  background-color: #ffbd59;
  margin: 2%;
  outline: none;
  border: none;
  padding: 1% 2%;
  color: #084f5b;
}

.SalesProjectRegister footer button:hover {
  background-color: #084f5b;
  color: #ffbd59;
}

.DesignProjectForm {
  display: flex;
  flex-direction: column;
  position: relative;
}

.DesignProjectListdropdown {
  position: absolute;
  background-color: whitesmoke;
  margin-top: 20%;
  width: 50%;
  margin-left: 40%;

  max-height: 100px;
  border-radius: 10px;
  overflow: auto;
  z-index: 100;
  color: #084f5b;
}

.DesignProjectListdropdown::-webkit-scrollbar {
  display: none;
}

.DesignProjectListdropdown li {
  list-style-type: none;
  padding: 8px;
  cursor: pointer;
  text-align: left;
}

.DesignProjectListdropdown li:hover {
  background-color: #084f5b;
  color: #ffbd59;
}

.employee-list {
  background-color: #ffbd59 !important;
  /* Change background color */
}

.employee-list option {
  /* Change text color */
  font-weight: bold;
  /* Make text bold */
  padding: 5px;
  /* Add padding */
}

.inline-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.inline-content a {
  color: #ffffff;
  /* Adjust text color */
  text-decoration: none;
}

.plus-icon img {
  width: 20px;
  /* Adjust size as needed */
  height: 20px;
}
</style>
