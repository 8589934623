<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="container-fluid companyDocumentPage mt-2">
          <div
            class="row ms-2 twentyPxFont text-decoration-underline"
         
          >
            Documents
          </div>
          <div class="d-flex justify-content-between p-1 " >
            <div class="mt-1">
              <img
                src="../assets/icons/leftmove.png"
                alt="Back"
                class="mb-1"
                @click="goToParentFolder('null')"
                style="width: 25px; height: 25px; padding: 5px; cursor: pointer"
              />
              <span
                class="ms-2"
                style=" cursor: pointer"
                @click="goToParentFolder('0')"
                >All Files
              </span>
              <span v-for="(headName, index) in HeaderName" :key="index">
                /
                <span
                  @click="goToHeaderFolder(headName.id, headName)"
                  style="cursor: pointer"
                >
                  {{ headName.folderName }}</span
                >
              </span>
            </div>
            <div class="d-flex FilesPageHeader">
              <section
                v-if="this.showExecative"
                class="p-1 mb-1"
                style="cursor: pointer; background-color: #f8f8f8"
              >
                <input
                  type="file"
                  @change="handleFileUpload"
                  multiple
                  accept=".pdf,.doc,.docx"
                  style="display: none"
                  ref="fileInput"
                />
                <img
                  src="../assets/icons/upload.png"
                  alt="Upload"
                  @click="$refs.fileInput.click()"
                />
                <span @click="$refs.fileInput.click()">Upload</span>
              </section>
              <section
                style="cursor: pointer; background-color: #f8f8f8"
                class="p-1 ms-2"
                @click="createFolder"
                v-if="this.showExecative"
              >
                <img src="../assets/icons/add.png" alt="Add" />
                <span>Create Folder</span>
              </section>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="files-table FolderTable">
              <caption class="d-none">
                Every Team Folder Structure
              </caption>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Created By</th>
                  <th>Last Modified</th>
                  <th>File Size</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(folder, index) in Folders" :key="index">
                  <td v-if="!folder.isNew">
                    <span
                      v-if="folder.folderName"
                      @click="goToFolder(folder.id, folder)"
                      style="cursor: pointer"
                    >
                      <img src="../assets/icons/folder.png" alt="" />
                      {{ folder.folderName }}
                    </span>

                    <a
                      style="cursor: pointer"
                      v-else
                      @click="
                        $viewFile(
                          folder['awsFiles.awsFileName'],
                          folder['awsFiles.fileName'],
                          'files',
                          this.ModuleName
                        )
                      "
                      target="_blank"
                    >
                    <img
                    src="../assets/icons/imageIcon.png"
                    alt=""
                    style="width: 20px; height: 20px"
                    v-if="folder['awsFiles.contentType'] === 'image/jpeg'"
                  />
                  <img
                  src="../assets/icons/dwg.jpeg"
                  alt=""
                  style="width: 20px; height: 20px"
                  v-if="folder['awsFiles.contentType'] === 'application/acad'"
                />
                <img
                src="../assets/icons/skp.jpeg"
                alt=""
                style="width: 20px; height: 20px"
                v-if="folder['awsFiles.contentType'] === 'application/vnd.sketchup.skp'"
              />
                      <img src="../assets/icons/PDF.png"      v-if="folder['awsFiles.contentType'] === 'application/pdf'" alt="" />

                     
                  
                      {{ folder["awsFiles.fileName"] }}
                      
                    </a>

                  </td>
                  <td v-else>
                    <input
                      type="text"
                      v-model="folder.folderName"
                      @blur="saveFolder(folder, index)"
                    />
                  </td>
                  <td>
                    <span v-if="folder.folderName">{{ folder.contentType }}</span>
                    <span v-else>{{ folder["awsFiles.contentType"] }}</span>
                  </td>

                  <td v-if="folder.folderName !== 'Sign Off Document'">
                    {{ folder["employees.firstName"] }}{{ folder["employees.lastName"] }}
                  </td>
                  <td v-else>System</td>
                  <td>{{ folder.updated_at }}</td>
                  <td>-</td>
                  <td>
                    <img
                      v-if="folder.folderName !== 'Sign Off Document'"
                      src="../assets/icons/3blackDots.png"
                      alt="Options"
                      style="height: 17px; cursor: pointer"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                    />
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                        class="dropdown-item"
                        v-if="folder.folderName"
                        href="#"
                        @click="Rename(folder)"
                        >Rename</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="deleteFolder(folder, index)"
                        >Delete</a
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "./Header.vue";
import Nav from "./Nav.vue";

export default {
  name: "CompanyDocuments",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      showExecutive: false,
      ParentFolderId: "0",
      ParentFolderName: "Root",
      Folders: [],
      attachedFiles: [],
      ModuleName: null,
      BackFolderId: "null",
      HeaderName: [],
      awsFileId: [],
    };
  },
  mounted() {
    this.ModuleName = this.$route.params.moduleName;
    this.checkExecutiveView(this.ModuleName);
    this.fetchFolderOrFiles();
  },
  watch: {
    "$route.params.moduleName": "updateDocumentPage",
  },
  methods: {
    updateDocumentPage(moduleName) {
      this.ModuleName = moduleName;
      this.checkExecutiveView(this.ModuleName);
      this.fetchFolderOrFiles();
    },

    checkExecutiveView(Team) {
      // Map the team names to the correct values
      const teamMap = {
        design: "Designing",
        procurement: "Procurement",
        onSite: "Onsite",
        sales: "Sales",
      };

      const targetTeam = teamMap[Team] || Team; // Default to the original if no mapping exists

      // Check if the user has a Manager role for the adjusted team
      this.showExecative = this.$LocalUser.employeeRoles.some(
        (role) => role.team === targetTeam && role.role === "Manager"
      );
    },

    async goToParentFolder(FolderId) {
      if (FolderId === "0") {
        // Changed to string comparison
        this.HeaderName = [];
        this.ParentFolderId = FolderId;
      } else if (FolderId === "null" && this.HeaderName.length > 1) {
        // Changed to string comparison
        const FolderIds = this.HeaderName[this.HeaderName.length - 2].id;
        this.HeaderName.splice(-1, 1);
        this.ParentFolderId = FolderIds;

        this.fetchFolderOrFiles();
      } else {
        this.HeaderName = [];
        this.FolderId = 0;
        this.ParentFolderId = FolderId;

        this.fetchFolderOrFiles();
      }
    },

    goToFolder(FolderId, Folder) {
      this.Folders = [];
      this.HeaderName.push(Folder);

      this.ParentFolderId = FolderId;
      this.ParentFolderName = Folder.folderName;

      this.fetchFolderOrFiles();
    },
    goToHeaderFolder(FolderId, Folder) {
      // Check if the folder is already present in HeaderName
      const folderIndex = this.HeaderName.indexOf(Folder);

      if (folderIndex === -1) {
        // Folder not present, add to the end
        this.HeaderName.push(Folder);
      } else {
        // Folder is present, remove subsequent folders
        this.HeaderName = this.HeaderName.slice(0, folderIndex + 1);
      }
      // Log the updated HeaderName array
      // Update the ParentFolderId
      this.ParentFolderId = FolderId;
      // Fetch the contents of the folder
      this.fetchFolderOrFiles();
    },

    async fetchFolderOrFiles() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/folder/getModuleWiseFiles/${this.ModuleName}/${this.ParentFolderId}`
        );

        this.Folders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    createFolder() {
      const newFolder = {
        isNew: true,
        folderName: "",
        createdBy: "Current User",
        lastModified: new Date().toLocaleDateString(),
      };
      this.Folders.push(newFolder);
    },
    async deleteFolder(Folder, index) {
      this.Folders.splice(index, 1);
      if (Folder.folderName) {
        try {
          await axios.delete(`${this.$apiEndPoint}/folder/deleteFolder`, {
            data: { id: [Folder.id] },
          });

          this.fetchFolderOrFiles();
        } catch (error) {
          this.$handleError(error);
        }
      } else {
        try {
          await axios.delete(`${this.$apiEndPoint}/files/deleteFolder`, {
            data: { id: [Folder.id] },
          });

          this.fetchFolderOrFiles();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    Rename(folder) {
      folder.isNew = true;
    },

    async saveFolder(folder, index) {
      if (folder.folderName.trim() === "") {
        alert("Folder name cannot be empty");
        return;
      }

      if (!folder.id)
        try {
          await axios.post(`${this.$apiEndPoint}/folder/`, {
            parentFolderId: this.ParentFolderId,
            folderName: folder.folderName,
            moduleName: this.ModuleName,
          });
          this.fetchFolderOrFiles();

          this.$set(this.Folders, index, {
            ...folder,
            isNew: false,
          });
        } catch (error) {
          this.$handleError(error);
        }
      else {
        try {
          await axios.put(`${this.$apiEndPoint}/folder/${folder.id}`, {
            folderName: folder.folderName,
          });

          this.fetchFolderOrFiles();
        } catch (error) {
          this.$handleError(error);
        }
      }
    },
    handleFileUpload(event) {
  const files = event.target.files;
  console.log(files);
  let fileCategory = "files";
  this.uploadedFileIds = []; // Clear or initialize the file IDs array

  const uploadPromises = []; // Array to hold promises for each file upload

  for (const file of files) {
    // Determine the content type based on the file extension if it's empty
    let contentType = file.type || this.getContentTypeByExtension(file.name);

    const payload = {
      fileName: file.name,
      fileSize: file.size,
      contentType: contentType,
      moduleName: this.ModuleName,
      fileCategory: fileCategory,
    };

    // Add each axios call to the promises array
    uploadPromises.push(
      axios
        .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
        .then((response) => {
          const signedUrl = response.data.s3URL;
          const fileId = response.data.id; // Get the ID from the response
          // Return a promise for the file upload
          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", contentType);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.$toast("File uploaded successfully", "success");
                  // Collect the file ID
                  this.uploadedFileIds.push(fileId);

                  resolve(fileId); // Resolve the promise with the file ID
                } else {
                  reject(new Error("Error uploading file to S3"));
                  this.$toast(`Error uploading file.`, "error");
                }
              }
            };
            xhr.send(file);
          });
        })
        .catch((error) => {
          this.$handleError(error);
        })
    );
  }

  // Once all file uploads are done, call UploadFilesToFolder
  Promise.all(uploadPromises)
    .then(() => {
      this.UploadFilesToFolder();
    })
    .catch((error) => {
      this.$handleError(error);
    });
},

// Utility function to get MIME type based on file extension
getContentTypeByExtension(fileName) {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'dwg':
      return 'application/acad';
    case 'skp':
      return 'application/vnd.sketchup.skp';
    // Add more cases as needed for other file types
    default:
      return 'application/octet-stream'; // Default binary type
  }
},

    async UploadFilesToFolder() {
      try {
        const requestBody = {
          folderId: this.ParentFolderId,
          moduleName: this.ModuleName,
          awsFileId: this.uploadedFileIds, // Use the collected file IDs
        };

        await axios.post(`${this.$apiEndPoint}/files`, requestBody);

        this.fetchFolderOrFiles(); // Fetch the updated folder or files
        this.uploadedFileIds = []; // Clear the file IDs array after the request
      } catch (error) {
        this.$handleError(error);
      }
    },

    async CreateSignOffDocument(FileId) {
      try {
        const requestBody = {
          fileId: FileId,
          onSiteProjectId: this.Id,
        };
        await axios.post(`${this.$apiEndPoint}/onSiteFiles/`, requestBody);
        this.fetchFolderOrFiles();
        this.attachedFiles = [];
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
<style scoped>
.companyDocumentPage {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  overflow-y: auto;
  height: 85%;
  padding-bottom: 20px;
}

.table-wrapper .files-table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}
.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:first-child {
  border-radius: 5px 0 0 5px;
}

.files-table th:last-child {
  border-radius: 0px 5px 5px 0px;
}

.FilesPageHeader section {
  background-color: #f5f5f5;
  border-radius: 5px;
  text-align: center;
  padding: 0 10px;

  letter-spacing: 1px;
}
.FilesPageHeader section:hover {
  background-color: #f8f8f8;
}

.FilesPageHeader section img {
  margin: 0 5px;
  height: 15px;
  width: 15px;
}
@media screen and (max-width: 768px) {
  .table-wrapper {
    max-height: 80%;
  }
}
</style>
