<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="salesTargetPage p-2">
        <div class="row" style="border-bottom: 5px solid #bcbfc6">
          <p class="mainHeading d-flex ms-4 p-1 mt-2 mb-2">Total Sales</p>
        </div>

        <div class="FormatTable">
          <div class="row headerOfTable">
            <div class="col-md-6 d-flex">
              <div class="col-md-5 d-flex flex-column mb-3" v-if="showExecative">
                <label for="input1" class="form-label text-muted" style="font-size: 12px"
                  >Executive Name</label
                >
                <select
                  style="
                    font-size: 12px;
                    height: 30px;
                    cursor: pointer;
                    :focus {
                      outline: none;
                      box-shadow: none;
                    }
                  "
                  class="form-select"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                >
                  <option value="All" selected>All Team Member</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-5 ms-3 d-flex flex-column mb-3">
                <label for="input2" class="form-label text-muted" style="font-size: 12px"
                  >Financial Year</label
                >
                <select
                  class="form-select"
                  v-model="selectedFinancialYear"
                  @change="setSelectedFinancialYear"
                  style="
                    font-size: 12px;
                    height: 30px;
                    cursor: pointer;
                    :focus {
                      outline: none;
                      box-shadow: none;
                    }
                  "
                >
                  <option
                    v-for="year in financialYears"
                    :key="year.fromYear"
                    :value="year.fromYear"
                  >
                    FY Apr {{ year.fromYear }} - Mar {{ year.toYear }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="table-wrapper">
            <table class="">
              <caption class="d-none">
                Table
              </caption>
              <thead>
                <tr>
                  <th class="tableHeaderHeading" v-if="selectedEmployeeId === 'All'">
                    Executive Name
                  </th>
                  <th class="tableHeaderHeading">Customer</th>
                  <th class="tableHeaderHeading">Customer Id</th>
                  <th class="text-end">Contact No</th>
                  <th class="text-center">Date</th>
                  <th class="text-center">Estimate No</th>
                  <th class="text-center">Amount</th>
                  <th class="tableHeaderHeading ps-1 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in TableData" :key="index">
                  <td v-if="selectedEmployeeId === 'All'">
                    {{ item["employees.firstName"] }} {{ item["employees.lastName"] }}
                  </td>
                  <td>
                    {{ item["customers.name"] }}
                  </td>
                  <td>{{ item["customers.customerNumber"] }}</td>
                  <td class="text-end">
                    {{ item["customers.contactNo"] }}
                  </td>
                  <td class="text-center">
                    {{ item.date }}
                  </td>
                  <td class="text-center">{{ item.estimateId }}</td>
                  <td class="text-end">
                    {{ $formatToIndianCurrency(item.grandTotal) }}
                  </td>
                  <td class="salesTargetAction text-center" v-if="showExecative">
                    <img
                      @click="
                        $viewFile(
                          item['awsFiles.awsFileName'],
                          item['awsFiles.fileName'],
                          'estimate',
                          'sales'
                        )
                      "
                      src="../../assets/icons/view.png"
                      alt=""
                      class="action-image"
                    />
                    <img
                      src="../../assets/icons/pinkDownold.png"
                      alt=""
                      class="action-image"
                      @click="
                        $downloadFile(
                          item['awsFiles.awsFileName'],
                          item['awsFiles.fileName'],
                          'estimate',
                          'sales'
                        )
                      "
                    />
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="font-weight: 600; border-top: 2px solid #000">
                  <td v-if="selectedEmployeeId === 'All'"></td>
                  <td class="text-start" style="font-weight: 600">Total Value</td>
                  <td></td>
                  <td class="text-end" style="font-weight: 600"></td>
                  <td class="text-end text-success" style="font-weight: 600"></td>
                  <td></td>
                  <td class="text-end text-success" style="font-weight: 600">
                    {{ $formatToIndianCurrency(this.Totalachivevalue) }}
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "TotalSales",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      showExecative: false,
      employees: [],
      selectedFinancialYear: new Date().getFullYear(), // Default to current year
      financialYears: [],
      userRoles: this.$LocalUser.employeeRoles,
      TableData: [],
      Totalachivevalue: null,
    };
  },
  mounted() {
    this.fetchEmployees();
    this.generateFinancialYears(); // Generate the financial years list
    this.setSelectedFinancialYear();

    this.showExecative = this.userRoles.some(
      (role) => role.team === "Sales" && role.role === "Manager"
    );
  },
  methods: {
    generateFinancialYears() {
      const currentYear = new Date().getFullYear();
      const yearsRange = [];

      // Create last 5 years and next 5 years
      for (let i = -5; i <= 5; i++) {
        const fromYear = currentYear + i;
        const toYear = fromYear + 1;
        yearsRange.push({ fromYear, toYear });
      }

      this.financialYears = yearsRange;
      this.selectedFinancialYear = currentYear; // Default to the current year
    },

    async fetchEmployees() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee/myTeam/Sales`);
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async setSelectedFinancialYear() {
      const year = this.selectedFinancialYear;
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/getSalesAmount/${this.selectedEmployeeId}/${year}`
        );
        this.TableData = response.data.salesAmount;
        this.Totalachivevalue = response.data.totalSalesAmount;
      } catch (error) {
        this.$handleError(error);
      }
    },

    setSelectedEmployee() {
      this.setSelectedFinancialYear(); // Fetch data for the selected employee and financial year
    },
  },
};
</script>

<style scoped>
.salesTargetPage {
  height: calc(100% - 74px);
  width: calc(100% - 74px);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  position: fixed;
}

.salesTargetAction img {
  padding: 5px;
  width: 30px; /* Reduce size of images */
  height: 30px; /* Set height explicitly */

  cursor: pointer;
}
.salesTargetAction img:hover {
  border-radius: 50%;
  background-color: #ccc;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

#LeadFollowDate {
  color: rgb(107, 60, 60);
}

.FormatTable {
  width: 100%;
  height: 100%;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 0.5%;
}

#addicon {
  width: 30px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.table-wrapper {
  max-height: 76%; /* Adjust the height based on your requirements */
  overflow-y: auto;
  border: 1px solid #ddd;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead,
tfoot {
  background-color: #f9f9f9;
  position: sticky;
  z-index: 1;
}

thead {
  top: 0;
}

tfoot {
  bottom: 0;
}

th,
td {
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 7px 8px;
  text-align: left;
  background-color: #ffffff;
}
th {
  background-color: #bcbfc6;
}

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  #addicon {
    width: 100%;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
